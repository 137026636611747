import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useHistory, Prompt ,useLocation} from "react-router-dom";

import UserInfo from "./UserInfo/UserInfo";
import Adress from "./Adress/Adress";
import Profile from "./Profile/Profile";
import Education from "./Education/Education";
import Company from "./Company/Company";
import Money from "./Money/Money";
import Family from "./Family/Family";
import Hope from "./Hope/Hope";
import "./user.scss";
import {
  homeRequestPost,
  homeRequestGet,
  requestGet,
} from "../../apis/request";
import HopeHate from "./Hope/HopeHate";
import HopeDetail from "./Hope/HopeDetail";
import moment from "moment";
import styled from "styled-components";
import BottomModal from "../Talk/BottomModal";
import SweetAlert from "react-bootstrap-sweetalert";

function User(props) {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [values, setValues] = useState({});
  const [attributes, setAttributes] = useState([]);
  // const [idealType, setIdealType] = useState({});
  const [sibling, setSibling] = useState(["sibling1"]);
  const [sido, setSido] = useState("");
  const [sidoTrue, setSidoTrue] = useState("");
  const [sidoJob, setSidoJob] = useState("");
  const [regCode, setRegCode] = useState("");
  // const [publicImg, setPublicImg] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [showPrompt, setShowPrompt] = useState(true);
  const [showBottomModal, setShowBottomModal] = useState(false);
  // const [PriviteImg, setPriviteImg] = useState(null);
  const [recommendcodeData, setRecommendcodeData] = useState(null);
  const [joinFromData, setJoinFromData] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // 현재 URL 정보 가져오기
  const history = useHistory();

  const callCodeAPI = async () => {
    const response = await requestGet(`/api/recommendcode/list`);
    setRecommendcodeData(response.data);
  };

  const calljoinCodeAPI = async () => {
    const response = await requestGet(`/api/join/from/list`);
    setJoinFromData(response.data);
  };

  function getInitParams(){
    const searchParams = new URLSearchParams(location.search); // 쿼리 파라미터 파싱
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    if (params.regCode&&params.regCode.length >0){
      setRegCode(params.regCode); // 상태에 저장
    }
    let inputparams={}
    if (params.name&&params.name.length >0){
      inputparams.name=params.name
    }
    if (params.phone&&params.phone.length >0){
      inputparams.phone=params.phone
    }
    if (params.gender&&params.gender.length >0){
      inputparams.gender=params.gender
    }
    setValues({
      ...values,
      ...inputparams,
    }); // 상태에 저장
  }
  useEffect(() => {
    calljoinCodeAPI();
    callCodeAPI();
    getInitParams()
  }, []);

  const callInfo = async () => {
    try {
      const response = await homeRequestGet("/api/attributes/list");
      setAttributes(response?.data?.industry?.value);
    } catch (error) {
      console.log(error);
    }
  };

  const callLocation = async () => {
    try {
      const response = await homeRequestGet("/api/region/list/json");
      setLocationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setShowPrompt(true);
  };

  const handleChangerecommand = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [e.target.name + "etc"]: e.target.value,
    });
  };

  const handleSidoChange = (e) => {
    if (e.target.name === "customAdress") {
      setSido(e.target.value);
    } else if (e.target.name === "jobaddres") {
      setSidoJob(e.target.value);
    } else {
      setSidoTrue(e.target.value);
    }
    if (e.target.value === "해외") {
      let str = "";
      locationData.forEach((item) => {
        if (item?.name === "해외") {
          str = item.list[0].regionCode;
        }
      });
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        [e.target.name + "googun"]: str,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        [e.target.name + "googun"]: "",
      });
    }
    setShowPrompt(true);
  };

  const handleOneCheck = (e) => {
    const { name, checked } = e.target;
    setValues((prevValues) => {
      if (checked) {
        return {
          ...prevValues,
          [name]: true,
        };
      } else {
        return {
          ...prevValues,
          [name]: false,
        };
      }
    });
    setShowPrompt(true);
  };

  const handleCheck = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      let updatedArray = prevValues[name] ? [...prevValues[name]] : [];
      if (prevValues[name]?.includes(value)) {
        const arr = updatedArray.filter((_value) => _value !== value);
        updatedArray = arr;
      } else {
        updatedArray.push(value);
      }
      return { ...prevValues, [name]: updatedArray };
    });
    setShowPrompt(true);
  };

  const handleMoneyChange = (e) => {
    const value = e.target.value?.replace(/\D/g, ""); // 숫자가 아닌 모든 문자 제거
    const formattedNumber = value?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 천 단위마다 콤마 추가
    setValues({
      ...values,
      [e.target.name]: formattedNumber,
    });
    setShowPrompt(true);
  };

  const handleDropChange = (name) => (selectedOption) => {
    setValues({
      ...values,
      [name]: selectedOption.value,
    });
    setShowPrompt(true);
  };

  useEffect(() => {
    getInitParams()
    const handleBeforeUnload = (e) => {
      e.preventDefault(); // 기본 동작 방지
      e.returnValue = ""; // 대부분의 브라우저에서 필요
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

  }, []);



  useEffect(() => {

    getInitParams()
    callInfo();
    callLocation();
  }, []);

  function toggleTab(e, tab) {
    e.preventDefault();
    if (
      activeTab === 5 &&
      Number(values.brothers) + Number(values.sisters) < values.birthOrder
    ) {
      return alert("형제관계를 다시 한번 확인해주세요.");
    }
    if (
      activeTab === 6 &&
      moment(values.marriageStartDate).isAfter(moment(values.marriageEndDate))
    ) {
      return alert(
        "결혼기간 시작날짜가 결혼기간 끝날짜 보다 늦을 수 없습니다."
      );
    }
    if (
      activeTab === 8 &&
      values.ideal_birthYearMin &&
      values.ideal_birthYearMax &&
      Number(values.ideal_birthYearMin) > Number(values.ideal_birthYearMax)
    ) {
      return alert("최소 나이가 최대 나이 보다 클 수 없습니다.");
    }
    if (
      activeTab === 8 &&
      values.ideal_heightMin &&
      values.ideal_heightMax &&
      Number(values.ideal_heightMin) > Number(values.ideal_heightMax)
    ) {
      return alert("최소 키가 최대 키 보다 클 수 없습니다.");
    }
    if (
      activeTab === 8 &&
      values.ideal_heightMin &&
      values.ideal_heightMax &&
      (Number(values.ideal_heightMin) < 100 ||
        Number(values.ideal_heightMax) < 100)
    ) {
      return alert("키 입력시 100 이상으로 입력해 주세요");
    }
    if (activeTab === 1 && values.customAdress && !values.customAdressgoogun) {
      return alert("거주지역의 시/도 선택시 시/구/군 은 필수선택 입니다.");
    }
    if (activeTab === 1 && values.tureHome && !values.tureHomegoogun) {
      return alert("본가 시/도 선택시 시/구/군 은 필수선택 입니다.");
    }
    if (activeTab === 3 && values.jobaddres && !values.jobaddresgoogun) {
      return alert("회사소재지 시/도 선택시 시/구/군 은 필수선택 입니다.");
    }
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 10) {
        var modifiedSteps = [...passedSteps, tab];
        setShowPrompt(false);
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
        window.scrollTo(0, 0);
      }
    }
  }

  const onSubmitApi = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!values.privacy_agree) {
      alert("개인정보 수집 및 이용에 동의하여야 등록 가능합니다.");
      setLoading(false);
      return;
    }
    try {
      let body = {
        regCode:regCode??undefined,
        name: values.name,
        gender: values.gender,
        birthday: values.birth,
        isMarry: values.isMarry,
        character: values.mystyle,
        height: values.height ? Number(values.height) : null,
        weight: values.weight ? Number(values.weight) : null,
        home: values.customAdressdetail,
        homeRegionCode: values.customAdressgoogun,
        parentHome: values.tureHomedetail,
        parentHomeRegionCode: values.tureHomegoogun,
        drink: values.drink,
        smoke: values.smoke,
        phone: values.phone,
        email: email,
        recommendCode: values.recommendCode,
        bloodType: values.bloodType,
        tatoo: values.tatoo,
        exercise: values.sport,
        military: values.army,
        religion: values.gido,
        selfAppearance: values.selfAppearance,
        mbti: values.mbti ? values.mbti.toUpperCase() : "",
        bodyCharacter: values.bodyCharacter,
        marriageStartDate: values.marriageStartDate,
        marriageEndDate: values.marriageEndDate,
        joinFrom: values.joinFrom,
        reason: values.reason,
        care: values.care,
        childrenNumber: values.childrenNumber,
        hobby: values.myhobby,
        drinkBottleLimit: Number(values.drinkBottleLimit),
        drinkCupLimit: Number(values.drinkCupLimit),
        status: "complete",
        education: {
          finalStage: values.finaledu,
          univName: values.hakname,
          univMajor: values.hakskill,
          univGradyear: values.hakenddate ? Number(values.hakenddate) : null,
          gradSchoolName: values.sukname,
          gradSchoolMajor: values.sukskill,
          gradSchoolGradyear: values.sukenddate
            ? Number(values.sukenddate)
            : null,
        },
        jobinfo: {
          job: values.jobname,
          career: values.career,
          extraJob: values.etcjobname,
          employmentType: values.jobstatus,
          workType: values.workstatus,
          yearIncome: values.yearmoney
            ? Number(values.yearmoney?.replace(/\D/g, ""))
            : 0,
          name: values.comname,
          department: values.department,
          position: values.level,
          address: values.jobaddresdetail,
          jobRegion: values.jobaddresgoogun,
        },
        assets: {
          movableAmount: values.movemoney
            ? Number(values.movemoney?.replace(/\D/g, ""))
            : 0,
          immovableAmount: values.nomovemoney
            ? Number(values.nomovemoney?.replace(/\D/g, ""))
            : 0,
          hasImmovable: values.hasnomovemoney,
          assistance: values.merrymoney,
          hasCar: values.hascar,
          carModelName: values.carname,
          madeBy: values.makecarname,
          assistanceExtra: values.assistanceExtra
            ? Number(values.assistanceExtra?.replace(/\D/g, ""))
            : 0,
        },
        familyRelation: {
          type: values.familyinfo,
          brothers: values.brothers ? Number(values.brothers) : null,
          sisters: values.sisters ? Number(values.sisters) : null,
          birthOrder: values.birthOrder ? Number(values.birthOrder) : null,
          fatherJob: values.fajob,
          motherJob: values.mojob,
          parentAsset: values.pamoney
            ? Number(values.pamoney?.replace(/\D/g, ""))
            : 0,
          knowParentAsset: values.knowParentAsset,
          isLiveAlone: values.livealone,
          withParent: values.pawithlive,
          parentIndep: values.paalone,
          isSplit: values.paend,
          parentStatus: values.pasad,
        },

        idealData: {
          birthYearMin: values.ideal_birthYearMin,
          birthYearMax: values.ideal_birthYearMax,
          heightMin: values.ideal_heightMin,
          heightMax: values.ideal_heightMax,
          education: values.ideal_education,
          appearance: values.ideal_appearance,
          industry: values.ideal_industry,
          income: values.ideal_income,
          isDink: values.isDink,
          personalAsset: values.ideal_personalAsset,
          hasImmovable: values.ideal_hasImmovable,
          parentalAsset: values.ideal_parentalAsset,
          religion: values.ideal_religion,
          allowRegion: JSON.stringify(values.ideal_allowRegion),
          character: values.ideal_caracter,
          priority: values.ideal_priority,
          hateIndustry: values.hateIndustry,
          hateMarriageHistory: values.hateMarriageHistory,
          hateSmokeType: values.hateSmokeType,
          hateTatoo: values.hateTatoo,
          hateParentStatus: values.hateParentStatus,
          hateHasCarType: values.hateHasCarType,
          idealHateDetail: values.idealHateDetail,
          hateEmploymentType: JSON.stringify(values.hateEmploymentType),
          hateInterest: values.hateInterest,
          idealDetail: values.hopeStyle,
          priority1: values.priority1,
          priority2: values.priority2,
          priority3: values.priority3,
        },
      };
      await homeRequestPost(`/api/member/register`, body);
      setLoading(false);
      setShowPrompt(false);
      setShowBottomModal(true);
      // history.push("/");
      // window.location.reload();
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert("전송 실패하였습니다.");
    }
  };

  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }
  const handleChangeOriginalRadio = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [e.target.name + "etc"]: "",
    });
  };

  const handleChangeBlur = (e) => {
    console.log("handleChangeBlur call");
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [e.target.name.replace(/etc$/, "")]: e.target.value,
    });
  };

  const getInputValue = (valuesInput, keyPropInput, valueInput) => {
    const etcValue = valuesInput?.[keyPropInput + "etc"];
    const keyValue = valuesInput?.[keyPropInput];
    if (etcValue !== undefined && etcValue !== null) {
      return etcValue;
    } else if (
      keyValue &&
      !valueInput.includes(keyValue) &&
      keyValue !== "기타"
    ) {
      return keyValue;
    }
    return ""; // 두 조건에 맞지 않는 경우 빈 문자열 반환
  };

  const handleChangeEtc = (e) => {
    console.log("handleChangeEtc: " + e.target.name);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckstr = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      let updatedArray = prevValues[name] ? [...prevValues[name]] : [];
      if (prevValues[name]?.includes(value)) {
        const arr = updatedArray.filter((_value) => _value !== value);
        updatedArray = arr;
      } else {
        updatedArray.push(value);
      }
      return { ...prevValues, [name]: updatedArray };
    });
    setShowPrompt(true);
  };
  const FrequencyEmails = [
    "@naver.com",
    "@gmail.com",
    "@daum.net",
    "@hanmail.net",
    "@yahoo.com",
    "@outlook.com",
    "@nate.com",
    "@kakao.com",
  ];
  const [email, setEmail] = useState(""); //이메일 input 값
  const [emailList, setEmailList] = useState(FrequencyEmails); //추천 이메일 리스트를 확인, 이메일 리스트 상태 관리
  const [selected, setSelected] = useState(-1); //키보드 선택
  const [isDrobBox, setIsDropbox] = useState(false); // 드롭박스 유무
  const inputRef = useRef(); //외부클릭 감지 확인

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsDropbox(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    getInitParams()
  }, [inputRef]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.includes("@")) {
      setIsDropbox(true);
      setEmailList(
        FrequencyEmails.filter((el) =>
          el.includes(e.target.value.split("@")[1])
        )
      );
    } else {
      setIsDropbox(false);
      setSelected(-1);
    }
  };

  const handleDropDownClick = (first, second) => {
    setEmail(`${first.split("@")[0]}${second}`);
    setIsDropbox(false);
    setSelected(-1);
  };

  const handleKeyUp = (e) => {
    if (isDrobBox) {
      if (e.key === "ArrowDown" && emailList.length - 1 > selected) {
        setSelected(selected + 1);
      }
      //emailList.length에 -1을 해주는 이유는 selected의 최대값을 맞춰주기 위해서이다.
      //예를들어 밑에 emailList 2개가 나왔다고 가정했을 때, selected값이 최대 1까지 변할 수 있게 해줘야한다.
      //'ArrowDown'키를 누르면 selected는 0이 되고, 한번 더 누르면 1이 되고, 그 다음은 더이상 옵션이 없기 때문에 키가 안먹히게 해주는 것이다.

      if (e.key === "ArrowUp" && selected >= 0) {
        setSelected(selected - 1);
      }
      if (e.key === "Enter" && selected >= 0) {
        handleDropDownClick(email, emailList[selected]);
      }
    }
  };

  const handlePhoneChange = (e) => {
    const regex = /^[0-9+-]*$/;

    if (regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  function SelectFn({
    name,
    keyProp,
    // values,
    value,
    type,
    defaultdate,
    handleChange,
    description,
    handleMoneyChange,
  }) {
    if (type === "textarea") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <textarea
                className="js-input-mask form-control customInput"
                name={keyProp}
                value={values?.[keyProp]}
                id={`${keyProp}Label`}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleChange}
                style={{ height: "300px", resize: "none" }}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "text") {
      if (keyProp === "mbti") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3">
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  inputmode="latin"
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        );
      }
      //  else if (keyProp === "recommendCode") {
      //   return (
      //     <Row className="rowBorderBottom pt-3 pb-3">
      //       <Col lg={12} className="d-flex flex-column">
      //         <div>
      //           <label htmlFor={`${keyProp}Label`} className="labelText">
      //             {name}
      //           </label>
      //           <select
      //             className="form-select customInput"
      //             name="recommendCode"
      //             onChange={handleChangerecommand}
      //             style={
      //               !values.recommendCode
      //                 ? {
      //                     color: "#BCBCBC",
      //                   }
      //                 : { color: "#333" }
      //             }
      //           >
      //             {!values.recommendCode ? (
      //               <option>추천인을 선택해 주세요.</option>
      //             ) : null}
      //             {recommendcodeData?.map((item, i) => {
      //               return (
      //                 <option value={item} key={i}>
      //                   {item}
      //                 </option>
      //               );
      //             })}
      //             <option value={"기타"}>기타</option>
      //           </select>
      //           {values?.recommendCodeetc === "기타" ? (
      //             <input
      //               type="text"
      //               className="js-input-mask form-control customInput"
      //               name="recommendCode"
      //               id="recommendCodeLabel"
      //               placeholder="추천인을 입력해 주세요."
      //               aria-label="추천인을 입력해 주세요."
      //               onChange={handleChange}
      //               style={{ marginTop: "20px" }}
      //             />
      //           ) : null}
      //         </div>
      //       </Col>
      //     </Row>
      //   );
      // }
      else if (keyProp === "joinFrom") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3">
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  className="form-select customInput"
                  name="joinFrom"
                  onChange={handleChangerecommand}
                  style={
                    !values.joinFrom
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {!values.joinFrom ? (
                    <option>가입 경로를 선택해 주세요.</option>
                  ) : null}
                  {joinFromData?.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                  <option value={"기타"}>기타</option>
                </select>
                {values?.joinFrometc === "기타" ? (
                  <input
                    type="text"
                    className="js-input-mask form-control customInput"
                    name="joinFrom"
                    id="joinFromLabel"
                    placeholder="가입 경로를 입력해 주세요."
                    aria-label="가입 경로를 입력해 주세요."
                    onChange={handleChange}
                    style={{ marginTop: "20px" }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="rowBorderBottom pt-3 pb-3">
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  value={values?.[keyProp]}
                  inputmode="latin"
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
                {description && (
                  <div className="descriptionText">{description}</div>
                )}
              </div>
            </Col>
          </Row>
        );
      }
    }
    if (type === "phone") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="text"
                value={values?.[keyProp]}
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handlePhoneChange}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "email") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div ref={inputRef} style={{ position: "relative" }}>
                <input
                  type="email"
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  className="js-input-mask form-control customInput"
                  value={email}
                  onChange={(e) => {
                    onChangeEmail(e);
                  }}
                  onKeyUp={handleKeyUp}
                />
                {isDrobBox && (
                  <MailTipUl>
                    {emailList.map((item, idx) => (
                      <MailTipLi
                        key={idx}
                        onMouseOver={() => setSelected(idx)}
                        onClick={() => handleDropDownClick(email, item)}
                        selected={selected === idx}
                      >
                        {email.split("@")[0]}
                        {item}
                      </MailTipLi>
                    ))}
                  </MailTipUl>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "money") {
      if (keyProp === "pamoney") {
        return (
          <>
            <Row className="rowBorderBottom pt-3 pb-3">
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    부모님 자산
                  </label>
                  <div className="d-flex radiowrap">
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"knowParentAsset"}
                        id={`knowParentAssetYesLabel`}
                        value={"알고있음"}
                        checked={values?.knowParentAsset === "알고있음"}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChange(e)}
                      />
                      <label
                        htmlFor={`knowParentAssetYesLabel`}
                        className="textLabel m-0"
                      >
                        알고있음
                      </label>
                    </div>
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"knowParentAsset"}
                        id={`knowParentAssetNoLabel`}
                        value={"모름"}
                        checked={values?.knowParentAsset === "모름"}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChange(e)}
                      />
                      <label
                        htmlFor={`knowParentAssetNoLabel`}
                        className="textLabel m-0"
                      >
                        모름
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="rowBorderBottom pt-3 pb-3">
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    {name}
                  </label>
                  <input
                    type="text"
                    value={values?.[keyProp]}
                    className="js-input-mask form-control customInput"
                    name={keyProp}
                    id={`${keyProp}Label`}
                    placeholder={`${name}을(를) 입력해 주세요.`}
                    aria-label={`${name}을(를) 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[keyProp])}</div>
                </div>
              </Col>
            </Row>
          </>
        );
      }
      // if (keyProp === "pamoney") {
      //   return (
      //     <>
      //       <Row className="rowBorderBottom pt-3 pb-3">
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label htmlFor={`${keyProp}Label`} className="labelText">
      //               부모님 자산
      //             </label>
      //             <div className="d-flex radiowrap">
      //               <div className="d-flex mr20 mb-2 align-items-center">
      //                 <input
      //                   type="radio"
      //                   name={"knowParentAsset"}
      //                   id={`knowParentAssetYesLabel`}
      //                   value={"알고있음"}
      //                   checked={values?.knowParentAsset === "알고있음"}
      //                   style={{
      //                     width: "20px",
      //                     height: "20px",
      //                     marginRight: "5px",
      //                   }}
      //                   onClick={(e) => handleChange(e)}
      //                 />
      //                 <label
      //                   htmlFor={`knowParentAssetYesLabel`}
      //                   className="textLabel m-0"
      //                 >
      //                   알고있음
      //                 </label>
      //               </div>
      //               <div className="d-flex mr20 mb-2 align-items-center">
      //                 <input
      //                   type="radio"
      //                   name={"knowParentAsset"}
      //                   id={`knowParentAssetNoLabel`}
      //                   value={"모름"}
      //                   checked={values?.knowParentAsset === "모름"}
      //                   style={{
      //                     width: "20px",
      //                     height: "20px",
      //                     marginRight: "5px",
      //                   }}
      //                   onClick={(e) => handleChange(e)}
      //                 />
      //                 <label
      //                   htmlFor={`knowParentAssetNoLabel`}
      //                   className="textLabel m-0"
      //                 >
      //                   모름
      //                 </label>
      //               </div>
      //             </div>
      //           </div>
      //         </Col>
      //       </Row>
      //       <Row className="rowBorderBottom pt-3 pb-3">
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label htmlFor={`${keyProp}Label`} className="labelText">
      //               {name}
      //             </label>
      //             <input
      //               type="text"
      //               value={values?.[keyProp]}
      //               className="js-input-mask form-control customInput"
      //               name={keyProp}
      //               id={`${keyProp}Label`}
      //               placeholder={`${name}을(를) 입력해 주세요.`}
      //               aria-label={`${name}을(를) 입력해 주세요.`}
      //               onChange={handleMoneyChange}
      //             />
      //             <div>{numberToKorean(values?.[keyProp])}</div>
      //           </div>
      //         </Col>
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label
      //               htmlFor={`parentExtraCommentLabel`}
      //               className="labelText"
      //             >
      //               비고
      //             </label>
      //             <input
      //               type="text"
      //               value={values?.parentExtraComment}
      //               className="js-input-mask form-control customInput"
      //               name={`parentExtraComment`}
      //               id={`parentExtraCommentLabel`}
      //               placeholder={`비고를 입력해 주세요.`}
      //               aria-label={`비고를 입력해 주세요.`}
      //               onChange={handleChange}
      //             />
      //           </div>
      //         </Col>
      //       </Row>
      //     </>
      //   );
      // } else if (keyProp === "nomovemoney") {
      //   return (
      //     <>
      //       <Row className="rowBorderBottom pt-3 pb-3">
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label htmlFor={`${keyProp}Label`} className="labelText">
      //               {name}
      //             </label>
      //             <input
      //               type="text"
      //               value={values?.[keyProp]}
      //               className="js-input-mask form-control customInput"
      //               name={keyProp}
      //               id={`${keyProp}Label`}
      //               placeholder={`${name}을(를) 입력해 주세요.`}
      //               aria-label={`${name}을(를) 입력해 주세요.`}
      //               onChange={handleMoneyChange}
      //             />
      //             <div>{numberToKorean(values?.[keyProp])}</div>
      //           </div>
      //         </Col>
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label
      //               htmlFor={`immovableExtraComment2Label`}
      //               className="labelText"
      //             >
      //               비고
      //             </label>
      //             <input
      //               type="text"
      //               value={values?.immovableExtraComment2}
      //               className="js-input-mask form-control customInput"
      //               name={`immovableExtraComment2`}
      //               id={`immovableExtraComment2Label`}
      //               placeholder={`비고를 입력해 주세요.`}
      //               aria-label={`비고를 입력해 주세요.`}
      //               onChange={handleChange}
      //             />
      //           </div>
      //         </Col>
      //       </Row>
      //     </>
      //   );
      // } else if (keyProp === "movemoney") {
      //   return (
      //     <>
      //       <Row className="rowBorderBottom pt-3 pb-3">
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label htmlFor={`${keyProp}Label`} className="labelText">
      //               {name}
      //             </label>
      //             <input
      //               type="text"
      //               value={values?.[keyProp]}
      //               className="js-input-mask form-control customInput"
      //               name={keyProp}
      //               id={`${keyProp}Label`}
      //               placeholder={`${name}을(를) 입력해 주세요.`}
      //               aria-label={`${name}을(를) 입력해 주세요.`}
      //               onChange={handleMoneyChange}
      //             />
      //             <div>{numberToKorean(values?.[keyProp])}</div>
      //           </div>
      //         </Col>
      //         <Col lg={12} className="d-flex flex-column">
      //           <div>
      //             <label
      //               htmlFor={`movableExtraCommentLabel`}
      //               className="labelText"
      //             >
      //               비고
      //             </label>
      //             <input
      //               type="text"
      //               value={values?.movableExtraComment}
      //               className="js-input-mask form-control customInput"
      //               name={`movableExtraComment`}
      //               id={`movableExtraCommentLabel`}
      //               placeholder={`비고를 입력해 주세요.`}
      //               aria-label={`비고를 입력해 주세요.`}
      //               onChange={handleChange}
      //             />
      //           </div>
      //         </Col>
      //       </Row>
      //     </>
      //   );
      // }
      else {
        return (
          <Row className="rowBorderBottom pt-3 pb-3">
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleMoneyChange}
                />
                <div>{numberToKorean(values?.[keyProp])}</div>
              </div>
            </Col>
          </Row>
        );
      }
    }
    if (type === "number") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="number"
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                onWheel={(e) => e.target.blur()}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleChange}
              />
              {description && (
                <div className="descriptionText">{description}</div>
              )}
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "date") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="date"
                className="js-input-mask form-control customInputLeft"
                name={keyProp}
                id={`${keyProp}Label`}
                max="9999-12-31"
                defaultValue={
                  defaultdate
                    ? moment().subtract(20, "y").format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleChange}
                style={
                  !values?.[keyProp]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "location") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <Row>
                <Col>시/도</Col>
              </Row>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={keyProp}
                value={values?.[keyProp]}
                onChange={handleSidoChange}
                // style={
                //   !values?.[keyProp]
                //     ? {
                //         color: "#BCBCBC",
                //       }
                //     : { color: "#333" }
                // }
              >
                {!values?.[keyProp] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {locationData.map((item, i) => {
                  return (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <Row>
                <Col>시/구/군</Col>
              </Row>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={`${keyProp}googun`}
                value={values?.[`${keyProp}googun`]}
                onChange={handleChange}
                // style={
                //   !values?.[`${keyProp}googun`]
                //     ? {
                //         color: "#BCBCBC",
                //       }
                //     : { color: "#333" }
                // }
              >
                {!values?.[`${keyProp}googun`] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {locationData.map((item, i) => {
                  let str =
                    keyProp === "customAdress"
                      ? sido
                      : keyProp === "jobaddres"
                      ? sidoJob
                      : sidoTrue;
                  if (item?.name === str) {
                    return item.list.map((el, j) => {
                      return (
                        <option value={el.regionCode} key={j}>
                          {el.name}
                        </option>
                      );
                    });
                  }
                })}
              </select>
              <div>
                <Row>
                  <Col>상세입력</Col>
                </Row>
                <input
                  type="text"
                  className="js-input-mask form-control customInput"
                  name={`${keyProp}detail`}
                  id={`${keyProp}detailLabel`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "select") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={keyProp}
                onChange={handleChange}
                style={
                  !values?.[keyProp]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              >
                {!values?.[keyProp] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {value?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "brothers") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`brothersLabel`} className="labelText">
                {name}
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="brothers"
                  id={`brothersLabel`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">남</label>
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="sisters"
                  id={`sistersLabel`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">녀</label>
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="birthOrder"
                  id={`birthOrderLabel`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">째</label>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "radio") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value.map((item, index) => {
                  if (item === "기타") {
                    return (
                      <div
                        key={index}
                        className="d-flex mr20 mb-2 align-items-center"
                      >
                        <input
                          type="radio"
                          name={keyProp}
                          value={item}
                          id={`${keyProp}${item}Label`}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                          // onChange={handleChange}
                        />
                        <label
                          htmlFor={`${keyProp}${item}Label`}
                          className="textLabel m-0"
                        >
                          {item}
                        </label>
                        <input
                          type="text"
                          className="js-input-mask form-control customInput"
                          name={`${keyProp}etc`}
                          id={`${keyProp}etc`}
                          value={getInputValue(values, keyProp, value)}
                          style={{ marginLeft: "10px", width: "200px" }}
                          onChange={handleChangeEtc}
                          onBlur={handleChangeBlur}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="d-flex mr20 mb-2">
                        <input
                          type="radio"
                          name={keyProp}
                          id={`${keyProp}${item}Label`}
                          value={item}
                          checked={values?.[keyProp] === item}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor={`${keyProp}${item}Label`}
                          className="textLabel m-0"
                        >
                          {item}
                        </label>
                      </div>
                    );
                  }
                })}
                {name === "주량" ? (
                  <div className="d-flex" style={{ alignItems: "flex-start" }}>
                    <label className="textLabel">소주</label>
                    <input
                      type="number"
                      className="js-input-mask customInput"
                      name="drinkBottleLimit"
                      id={`${keyProp}Label`}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      style={{
                        width: "44px",
                        padding: "0px 0px 0px 0.7rem",
                        textAlign: "end",
                      }}
                    />
                    <label className="textLabel">병</label>
                    <input
                      type="number"
                      className="js-input-mask customInput"
                      name="drinkCupLimit"
                      id={`${keyProp}Label`}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      style={{
                        width: "44px",
                        padding: "0px 0px 0px 0.7rem",
                        textAlign: "end",
                      }}
                    />
                    <label className="textLabel">잔</label>
                  </div>
                ) : null}
                {name === "혼인 시 경제적 지원" ? (
                  <div>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="assistanceExtra"
                      id={`assistanceExtraLabel`}
                      value={values["assistanceExtra"]}
                      placeholder={`금액을 입력해 주세요.`}
                      aria-label={`금액을 입력해 주세요.`}
                      disabled={values?.merrymoney === "있음" ? false : true}
                      onChange={handleMoneyChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
          {name === "혼인 시 경제적 지원" ? (
            values?.merrymoney === "있음" ? (
              <Col lg={12} className="d-flex flex-column">
                <div>{numberToKorean(values?.["assistanceExtra"])}</div>
              </Col>
            ) : null
          ) : null}
          {keyProp === "paalone" ? (
            <Col lg={12} className="d-flex flex-column">
              <div
                style={{
                  wordBreak: "keep-all",
                }}
              >
                기준 : 결혼 후 부모님의 생활비를 정기적으로 드려야 한다면 노후
                준비 여부 '불가'로 판단
              </div>
            </Col>
          ) : null}
        </Row>
      );
    }
    if (type === "etc") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex mr20 mb-2 align-items-center"
                  >
                    <input
                      type="radio"
                      name={keyProp}
                      id={`${keyProp}${item}Label`}
                      value={item}
                      checked={values?.[keyProp] === item}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChangeOriginalRadio(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item}Label`}
                      className="textLabel m-0"
                    >
                      {item}
                    </label>
                  </div>
                ))}
                <div className="d-flex mr20 mb-2 align-items-center">
                  <input
                    type="radio"
                    value="기타"
                    id={`${keyProp}기타Label`}
                    name={keyProp}
                    checked={
                      values?.[keyProp]
                        ? !value.includes(values?.[keyProp])
                          ? true
                          : false
                        : false
                    }
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    onClick={(e) => handleChange(e)}
                  />
                  <label htmlFor={`${keyProp}etc`} className="textLabel m-0">
                    기타
                  </label>
                  <input
                    type="text"
                    className="js-input-mask form-control customInput"
                    name={`${keyProp}etc`}
                    id={`${keyProp}etc`}
                    value={getInputValue(values, keyProp, value)}
                    style={{ marginLeft: "10px", width: "200px" }}
                    onChange={handleChangeEtc}
                    onBlur={handleChangeBlur}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "check") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {attributes?.map((item, index) => (
                  <div key={index} className="d-flex mr20 mb-2">
                    <input
                      type="checkbox"
                      name={keyProp}
                      value={item.id}
                      id={`${keyProp}${item.value}Label`}
                      checked={values?.[keyProp]?.includes(String(item.id))}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleCheck(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item.value}Label`}
                      className="textLabel m-0"
                    >
                      {item.value}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "checks") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value?.map((item, index) => (
                  <div key={index} className="d-flex mr20 mb-2">
                    <input
                      type="checkbox"
                      name={keyProp}
                      id={`${keyProp}${item}Label`}
                      value={item}
                      checked={values?.[keyProp]?.includes(item)}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleCheckstr(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item}Label`}
                      className="textLabel m-0"
                    >
                      {item}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }
  return (
    <React.Fragment>
      <Prompt
        when={showPrompt} // 조건에 따라 이 값을 변경하여 Prompt의 활성화/비활성화를 제어할 수 있습니다.
        message="이 페이지를 떠나시겠습니까? 변경사항이 저장되지 않을 수 있습니다."
      />
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <div className="page-content customWrap">
        <Container>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div
                      className="content clearfix"
                      style={{ padding: "14px" }}
                    >
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <UserInfo
                            values={values}
                            handleChange={handleChange}
                            SelectFn={SelectFn}
                          />
                        </TabPane>
                        <TabPane tabId={2}>
                          <Education
                            handleChange={handleChange}
                            handleOneCheck={handleOneCheck}
                            SelectFn={SelectFn}
                          />
                        </TabPane>
                        <TabPane tabId={3}>
                          <Company
                            handleChange={handleChange}
                            attributes={attributes}
                            SelectFn={SelectFn}
                            values={values}
                            handleMoneyChange={handleMoneyChange}
                          />
                        </TabPane>
                        <TabPane tabId={4}>
                          <Money
                            handleChange={handleChange}
                            attributes={attributes}
                            SelectFn={SelectFn}
                            values={values}
                            handleMoneyChange={handleMoneyChange}
                          />
                        </TabPane>
                        <TabPane tabId={5}>
                          <Family
                            handleChange={handleChange}
                            handleDropChange={handleDropChange}
                            attributes={attributes}
                            values={values}
                            sibling={sibling}
                            setSibling={setSibling}
                            SelectFn={SelectFn}
                            handleMoneyChange={handleMoneyChange}
                          />
                        </TabPane>
                        <TabPane tabId={6}>
                          <Adress
                            handleChange={handleChange}
                            handleDropChange={handleDropChange}
                            attributes={attributes}
                            values={values}
                            sibling={sibling}
                            setSibling={setSibling}
                            SelectFn={SelectFn}
                          />
                        </TabPane>
                        <TabPane tabId={7}>
                          <Profile handleChange={handleChange} />
                        </TabPane>
                        <TabPane tabId={8}>
                          <Hope
                            handleChange={handleChange}
                            attributes={attributes}
                            // idealType={idealType}
                            handleCheck={handleCheck}
                            SelectFn={SelectFn}
                            handleCheckstr={handleCheckstr}
                            values={values}
                          />
                        </TabPane>
                        <TabPane tabId={9}>
                          <HopeHate
                            handleChange={handleChange}
                            attributes={attributes}
                            // idealType={idealType}
                            handleCheck={handleCheck}
                            SelectFn={SelectFn}
                            handleCheckstr={handleCheckstr}
                            values={values}
                          />
                        </TabPane>
                        <TabPane tabId={10}>
                          <HopeDetail
                            handleChange={handleChange}
                            attributes={attributes}
                            // idealType={idealType}
                            handleOneCheck={handleOneCheck}
                            values={values}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul className="d-flex justify-content-end">
                        <li
                          className={
                            activeTab === 1
                              ? "previous disabled noDot"
                              : "previous noDot"
                          }
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              toggleTab(e, activeTab - 1);
                            }}
                          >
                            이전
                          </Link>
                        </li>
                        <li
                          className={
                            activeTab === 10
                              ? "next disabled noDot"
                              : "next noDot"
                          }
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              toggleTab(e, activeTab + 1);
                            }}
                          >
                            다음
                          </Link>
                        </li>
                        <li
                          className={
                            activeTab !== 10 ? "d-none noDot" : "finish noDot"
                          }
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              onSubmitApi(e);
                            }}
                            disabled={loading}
                          >
                            완료
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {showBottomModal ? (
        <BottomModal
          setShowBottomModal={setShowBottomModal}
          setShowPrompt={setShowPrompt}
        />
      ) : null}
    </React.Fragment>
  );
}

export default User;

const MailTipUl = styled.ul`
  width: 100%;
  max-height: 100px;
  position: absolute;
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
  background-color: #fff;
  padding-left: 0;
  z-index: 1;
  overflow: auto;
`;

const MailTipLi = styled.li`
  list-style: none;
  padding: 0.47rem 0.75rem;
  background-color: ${({ selected }) => (selected ? "#f5f5f5" : "")};
  color: ${({ selected }) => (selected ? "var(--zu--m4-color)" : "")};
`;
