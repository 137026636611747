import React, { useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useParams, Link } from "react-router-dom";

import { Col, Input, Label, Row } from "reactstrap";

import "../Consult/consult.scss";
import BackImg from "../../assets/img/others/Logo1.png";
import Rectangle from "./Rectangle2.png";
import { adminRequestPost, adminUserPut, requestGet } from "../../apis/request";
import PremiumMadal from "./PremiumMadal";
import WaringModal from "./WaringModal";
import { ReactComponent as Frame } from "../../assets/icon/svgs/Frame.svg";
import { ReactComponent as Objects } from "./OBJECTS.svg";
import { ReactComponent as CommentIcon } from "./commentIcon.svg";
import InfoModal from "./InfoModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NomalPost from "./NomalPost";
import RejectPost from "./RejectPost";
import PremiumPost from "./PremiumPost";
import LikePostComplete from "./LikePostComplete";
import moment from "moment";
import ErrorModal from "./ErrorModal";
import RejectMassegeModal from "./RejectMassegeModal";
import ErrorModalBack from "./ErrorModalBack";

function IntroDetail(props) {
  const [values, setValues] = useState(null);
  const [dday, setDday] = useState(null);
  const [likeStatus, setLikeStatus] = useState(null);
  const [likeMessage, setLikeMessage] = useState(null);
  const [editData, setEditData] = useState(null);
  const [callPremiumModal, setCallPremiumModal] = useState(false);
  const [callNomalModal, setCallNomalModal] = useState(false);
  const [message, setMessage] = useState("");
  const [completeMessage, setCompleteMessage] = useState("");
  const [likeAnswer, setLikeAnswer] = useState("");
  const [type, setType] = useState("");
  const [callRejectMessageModal, setCallRejectMessageModal] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [callRejectModal, setCallRejectModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [waring, setWaring] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [callCompleteModal, setCallCompleteModal] = useState(false);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [callErrorModal, setCallErrorModal] = useState(false);
  const [callErrorBackModal, setCallErrorBackModal] = useState(false);

  const [useSubscribe, setUseSubscribe] = useState(false);  

  const textareaRef1 = useRef(null);
  const textareaRef3 = useRef(null);
  const yearOfBirthToAge = function (birthDayString) {
    
    var birthYear = parseInt(birthDayString.substring(0, 4), 10);
    var birthMonth = parseInt(birthDayString.substring(4, 6), 10) - 1; // Month is zero-based
    var birthDay = parseInt(birthDayString.substring(6, 8), 10);

    // var birthDate = new Date(birthDayString);
    // // 생년월일을 '년', '월', '일'로 분리합니다.
    // var birthYear = birthDate.getFullYear();
    // var birthMonth = birthDate.getMonth();
    // var birthDay = birthDate.getDate();

    // 현재 날짜를 가져옵니다.
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();
    var currentDay = currentDate.getDate();

    // 만 나이를 계산합니다.
    var age = currentYear - birthYear;

    // 현재 월과 생일의 월을 비교합니다.
    if (currentMonth < birthMonth) {
      age--;
    }
    // 현재 월과 생일의 월이 같은 경우, 현재 일과 생일의 일을 비교합니다.
    else if (currentMonth === birthMonth && currentDay < birthDay) {
      age--;
    }

    return age;
  };
  
  const options = [
    {
      name: "나이",
      key: "birthyear",
    },
    {
      name: "키",
      key: "height",
    },
    {
      name: "결혼 여부",
      key: "isMarry",
    },
    {
      name: "거주지역",
      key: "home",
    },
    {
      name: "가족관계",
      key: "brosis",
    },
    {
      name: "최종학력",
      key: "finalStage",
    },
    {
      name: "대학명(학사)",
      key: "univName",
    },
    {
      name: "대학원명(석,박사)",
      key: "gradSchoolName",
    },
    {
      name: "직장명",
      key: "workName",
    },
    {
      name: "직장지역",
      key: "address",
    },
    {
      name: "직업 분류",
      key: "job",
    },
    {
      name: "고용형태",
      key: "employmentType",
    },
    {
      name: "근무형태",
      key: "workType",
    },
    {
      name: "연봉(세전)",
      key: "yearIncome",
    },
    {
      name: "인증 연봉(세전)",
      key: "checkYearIncome",
    },
    {
      name: "유동자산",
      key: "movableAmount",
    },
    {
      name: "인증 유동자산",
      key: "checkMovableAmount",
    },
    {
      name: "비유동자산(부동산 포함)",
      key: "immovableAmount",
    },
    {
      name: "인증 비유동자산(부동산 포함)",
      key: "checkImmovableAmount",
    },
    {
      name: "인증 부채자산",
      key: "checkDebt",
    },
    {
      name: "자가 등 비유동자산(부동산 포함) 소유여부",
      key: "hasImmovable",
    },
    {
      name: "아버지 직업",
      key: "fatherJob",
    },
    {
      name: "어머니 직업",
      key: "motherJob",
    },
    {
      name: "부모 자산",
      key: "parentAsset",
    },
    {
      name: "인증 부모 자산",
      key: "checkParentAsset",
    },
    {
      name: "인증 부모 부채자산",
      key: "checkParentDebt",
    },
    {
      name: "노후 준비 여부",
      key: "parentIndep",
    },
    {
      name: "흡연여부",
      key: "smoke",
    },
    {
      name: "음주여부",
      key: "drink",
    },
    {
      name: "종교",
      key: "religion",
    },
    {
      name: "자기소개",
      key: "character",
    },
  ];

  const { id, birth } = useParams();

  const calculateDday = (targetDate, isMatched) => {
    const now = new Date();
    const target = new Date(targetDate);
    const difference = target - now;

    if (difference < 0) {
      return "Expired";
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      if(isMatched){
        return `첫 만남 이후에 카드가 사라집니다.`;
      } else {
        if (days > 0) {
          return `${days}일 뒤 카드가 사라집니다.`;
        } else if (hours > 1) {
          return `${hours}시간 뒤 카드가 사라집니다.`;
        } else {
          return `잠시 후 카드가 사라집니다.`;
        }
      }
      
    }
  };

  const callInfp = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      params.append("birthday", birth);
      const response = await requestGet(`/api/introduce/info/${id}?${params}`);
      console.log("response", response);
      setDday(response.expireDate);
      setLikeStatus(response.likeStatus);
      setValues(response.data);
      setLikeMessage(response.likeMessage);
      setLikeAnswer(response.likeAnswer);
      if (response.likeStatus === "normal-like-reject" && response.likeAnswer) {
        setType("일반");
        setCallRejectMessageModal(true);
      } else if (
        response.likeStatus === "premium-like-reject" &&
        response.likeAnswer
      ) {
        setType("프리미엄");
        setCallRejectMessageModal(true);
      }

      if(response.data && response.data.userSubScription){
        setUseSubscribe(true);
      } else {
        setUseSubscribe(false);
      }
    } catch (error) {
      setErrorMessage(error.data.message);
      setCallErrorBackModal(true);

      setUseSubscribe(false);
    }
  }, [id]);

  useEffect(() => {
    callInfp();
  }, [callInfp, id]);

  useEffect(() => {
    const hideUntil = localStorage.getItem("hideUntil");
    if (hideUntil) {
      const now = new Date().getTime();
      const hideUntilTime = parseInt(hideUntil, 10);

      // 현재 시간이 저장된 'hideUntil' 시간보다 크다면 요소를 다시 보여줍니다.
      if (now > hideUntilTime) {
        setIsVisible(true);
        setWaring(false);
        localStorage.removeItem("hideUntil"); // 시간이 경과했으므로 'hideUntil' 항목을 제거합니다.
      } else {
        setIsVisible(false);
        setWaring(true);
      }
    }
  }, []);

  const postLike = async (message, type) => {
    try {
      let body = {
        uuid: id,
        birthday: birth,
        type: 'normal',
        message: message,
      };
      await adminRequestPost(`/api/like`, body);
      if (type === "normal") {
        // setCompleteMessage("일반 호감을 보냈습니다.");
        setCompleteMessage("호감을 보냈습니다.");
      }
      if (type === "premium") {
        setCompleteMessage("호감을 보냈습니다.");
        // setCompleteMessage("프리미엄 호감을 보냈습니다.");
      }
      setCallCompleteModal(true);
    } catch (error) {
      if (error.data.error) {
        setErrorMessage(error.data.error);
      } else if (error.data.message) {
        setErrorMessage(error.data.message);
      }
      setError(true);
      setCallErrorModal(true);
    }
  };

  const postReject = async () => {
    try {
      let body = {
        uuid: id,
        birthday: birth,
      };
      await adminUserPut(`/api/introduce/reject`, body);
      setCompleteMessage("거절 하였습니다.");
      setCallCompleteModal(true);
    } catch (error) {
      if (error.data.error) {
        setErrorMessage(error.data.error);
      } else if (error.data.message) {
        setErrorMessage(error.data.message);
      }
      setError(true);
      setCallErrorModal(true);
    }
  };

  const premiumpostLike = () => {
    setCallModal(true);
  };
  const nomalpostLike = () => {
    // setCallNomalModal(true);
    setCallModal(true);
  };
  const rejectpostLike = () => {
    setCallRejectModal(true);
  };

  useEffect(() => {
    if (values) {
      var isMatched = false;
      if(likeStatus && likeStatus === "accept"){
        isMatched = true;
      }
      setEditData({
        birthday: values.birthday,
        // birthyear: `${values?.birthday[2]}${values?.birthday[3]}년생`,
        birthyear: values.birthday ? "만 " +   yearOfBirthToAge(values.birthday) + "세" :"" ,
        comment: values.comment,
        dDay: calculateDday(moment(dday).format("YYYY-MM-DD HH:mm:ss"), isMatched),
        // dDay: values.dDay,
        hasImmovable: values.assets.hasImmovable,
        extraComment: values.assets.extraComment,
        extraComment2: values.assets.extraComment2,
        immovableAmount: values.assets.immovableAmount
          ? String(values.assets.immovableAmount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.assets.immovableAmount)})`
          : "",
        movableAmount: values.assets.movableAmount
          ? String(values.assets.movableAmount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.assets.movableAmount)})`
          : "",
        checkImmovableAmount: values.assets.checkImmovableAmount
          ? String(values.assets.checkImmovableAmount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.assets.checkImmovableAmount)})`
          : "",
        checkDebt: values.assets.checkDebt
          ? String(values.assets.checkDebt).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.assets.checkDebt)})`
          : "",
        checkMovableAmount: values.assets.checkMovableAmount
          ? String(values.assets.checkMovableAmount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.assets.checkMovableAmount)})`
          : "",
        character: values.character,
        drink: values.drink,
        finalStage: values.education.finalStage,
        univName: values.education.univName,
        gradSchoolName: values.education.gradSchoolName,
        fatherJob: values.familyRelation.fatherJob,
        motherJob: values.familyRelation.motherJob,
        isOpenCheckParentDebt: values.familyRelation.isOpenCheckParentDebt,
        isOpenCheckParentImmovableAmount:
          values.familyRelation.isOpenCheckParentImmovableAmount,
        isOpenParentImmovableAmount:
          values.familyRelation.isOpenParentImmovableAmount,
        isOpenCheckParentAsset: values.familyRelation.isOpenCheckParentAsset,
        isOpenParentAsset: values.familyRelation.isOpenParentAsset,
        isOpenFatherJob: values.familyRelation.isOpenFatherJob,
        isOpenMotherJob: values.familyRelation.isOpenMotherJob,
        parentIndep: values.familyRelation.parentIndep,
        extraComment3: values.familyRelation.extraComment,
        parentAsset: values.familyRelation.parentAsset
          ? String(values.familyRelation.parentAsset).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.familyRelation.parentAsset)})`
          : "",
        checkParentAsset: values.familyRelation.checkParentAsset
          ? String(values.familyRelation.checkParentAsset).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.familyRelation.checkParentAsset)})`
          : "",
        parentImmovableAmount: values.familyRelation.parentImmovableAmount
          ? String(values.familyRelation.parentImmovableAmount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.familyRelation.parentImmovableAmount)})`
          : "",
        checkParentImmovableAmount: values.familyRelation
          .checkParentImmovableAmount
          ? String(values.familyRelation.checkParentImmovableAmount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(
              values.familyRelation.checkParentImmovableAmount
            )})`
          : "",
        checkParentDebt: values.familyRelation.checkParentDebt
          ? String(values.familyRelation.checkParentDebt).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.familyRelation.checkParentDebt)})`
          : "",
        brosis:
          (values.familyRelation.brothers
            ? values.familyRelation.brothers + "남"
            : "0남") +
          (values.familyRelation.sisters
            ? values.familyRelation.sisters + "녀"
            : "0녀") +
          (values.familyRelation.birthOrder
            ? values.familyRelation.birthOrder + "째"
            : "0째"),
        height: values.height ? values.height + "cm" : "",
        home: values.home,
        isMarry: values.isMarry,
        managerComment: values.managerComment,
        address: values.jobinfo.jobRegionFullName,
        workextraComment: values.jobinfo.extraComment,
        employmentType: values.jobinfo.employmentType,
        workType: values.jobinfo.workType,
        workName: values.jobinfo.name,
        job: values.jobinfo.career,
        yearIncome: values.jobinfo.yearIncome
          ? String(values.jobinfo.yearIncome).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.jobinfo.yearIncome)})`
          : "",
        checkYearIncome: values.jobinfo.checkYearIncome
          ? String(values.jobinfo.checkYearIncome).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ) +
            "원" +
            `(${numberToKorean(values.jobinfo.checkYearIncome)})`
          : "",
        name: values.name,
        religion: values.religion,
        smoke: values.smoke,
        publicImages: values.publicImages,
      });
    }
  }, [values, dday]);

  // useEffect(() => {
  //   callInfp();
  // }, [callInfp, id]);

  const onInfoModa = (e) => {
    e.stopPropagation();
    setInfoModal(true);
  };

  useEffect(() => {
    if (containerRef.current) {
      const updateSize = () => {
        const width = containerRef.current.offsetWidth;
        setContainerSize({ width, height: width });
      };

      // Initial size calculation
      updateSize();

      // Recalculate size on window resize
      window.addEventListener("resize", updateSize);

      // Clean up the event listener
      return () => window.removeEventListener("resize", updateSize);
    }
  }, [containerRef]);

  const handleImageLoad = () => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setContainerSize({ width, height: width });
    }
  };

  const onPremiumModal = (value) => {
    setMessage(value);
    setCallPremiumModal(true);
  };
  const onErrorModal = (value) => {
    setErrorMessage(value);
    setCallErrorModal(true);
  };
  

  useEffect(() => {
    if (textareaRef3.current) {
      const textarea = textareaRef3.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [editData, textareaRef3, options]);

  useEffect(() => {
    const textarea1 = textareaRef1.current;
    if (textarea1) {
      textarea1.style.height = "auto";
      textarea1.style.height = `${textarea1.scrollHeight}px`;
    }
  }, [editData, textareaRef1, options]);

  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }

  return (
    <React.Fragment>
      {waring ? (
        <div
          className="container d-flex"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <img
            src={Rectangle}
            alt="top"
            style={{ position: "absolute", top: "0", left: "0", width: "100%" }}
          />
          <div
            className="d-flex flex-column w-100"
            style={{ paddingBottom: "40px", zIndex: 1 }}
          >
            <Row>
              <Col>
                <div className="introMainText">
                  소개를 받은 상대의 프로필입니다.
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="introNameText">{editData?.name}님</div>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                className="d-flex flex-column align-items-center"
                style={{ padding: "0px 38px" }}
              >
                <div className="w-100">
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "6px",
                      color: "#fff",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        to={`/image?src=${encodeURIComponent(
                          editData?.publicImages?.[0]?.url
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ position: "relative" }}
                      >
                        <img
                          alt="profile"
                          src={
                            editData?.publicImages?.[0]?.url
                              ? editData.publicImages[0].url
                              : BackImg
                          }
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                        <span className="ddaycard">{editData?.dDay}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0px 38px" }}>
                <div className="snapdiffentment">
                  <Objects />
                  야외 스냅 촬영의 경우 햇빛에 따른 피부 톤 차이가 있을 수
                  있습니다.
                </div>
              </Col>
            </Row>
            {editData?.comment ? (
              <Row style={{ marginBottom: "30px" }}>
                <Col lg={12} style={{ padding: "0px 24px" }}>
                  <div className="talkcard">
                    <div className="talkheader">
                      <CommentIcon className="talkicon" />
                      <h3>담당 매니저의 회원 소개</h3>
                    </div>
                    <div className="talkcontent">
                      <textarea
                        ref={textareaRef3}
                        readOnly
                        value={editData?.comment}
                        className="talkcharacter-textarea"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row style={{ marginBottom: "10px" }}>
              <Col lg={12} style={{ padding: "0px 24px" }}>
                <div className="profile-container">
                  <h2 className="profile-title">Profile.</h2>
                  <hr className="profile-underline" />
                </div>
              </Col>
            </Row>
            {options.map((item, i) => {
              const key = `${i}-${item.key}`;
              if (item.key === "home") {
                if (editData?.home?.length > 0) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "comment") {
                return null;
              } else if (item.key === "character") {
                return (
                  <Row key={key} style={{ marginBottom: "30px" }}>
                    <Col lg={12} style={{ padding: "0px 24px" }}>
                      <div>
                        <label className="introlabelText">{item.name}</label>
                        <textarea
                          ref={textareaRef1}
                          readOnly
                          className="js-input-mask introlabelInput"
                          value={editData?.[item.key]}
                          style={{ resize: "none" }}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              } else if (item.key === "univName") {
                if (editData?.univName) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "gradSchoolName") {
                if (editData?.gradSchoolName) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "workName") {
                if (editData?.workName) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "yearIncome") {
                return (
                  <div key={key}>
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                  </div>
                );
              } else if (item.key === "checkYearIncome") {
                return (
                  <div key={key}>
                    <Row>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        {editData?.workextraComment ? (
                          <div>우연 인증팀 : {editData?.workextraComment}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                );
              } else if (item.key === "movableAmount") {
                return (
                  <div key={key}>
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                  </div>
                );
              } else if (item.key === "checkMovableAmount") {
                return (
                  <div key={key}>
                    <Row>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        {editData?.extraComment ? (
                          <div>우연 인증팀 : {editData?.extraComment}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                );
              } else if (item.key === "immovableAmount") {
                return (
                  <div key={key}>
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                  </div>
                );
              } else if (item.key === "checkImmovableAmount") {
                return (
                  <div key={key}>
                    <Row>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        {editData?.extraComment2 ? (
                          <div>우연 인증팀 : {editData?.extraComment2}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                );
              } else if (item.key === "checkDebt") {
                return (
                  <div key={key}>
                    <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>{numberToKorean(editData?.[item.key])}</div>
                      </Col>
                    </Row> */}
                  </div>
                );
              } else if (item.key === "fatherJob") {
                if (editData?.isOpenFatherJob) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "motherJob") {
                if (editData?.isOpenMotherJob) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "parentAsset") {
                if (editData?.isOpenParentAsset) {
                  return (
                    <Row key={key}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                      {/* <Row>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          <div>{numberToKorean(editData?.[item.key])}</div>
                        </Col>
                      </Row> */}
                      <Row style={{ marginBottom: "30px" }}>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          {!editData?.isOpenCheckParentAsset ? (
                            editData?.extraComment3 ? (
                              <div>우연 인증팀 : {editData?.extraComment3}</div>
                            ) : null
                          ) : null}
                        </Col>
                      </Row>
                    </Row>
                  );
                } else if (
                  !editData?.isOpenParentAsset &&
                  !editData?.isOpenCheckParentAsset &&
                  editData?.extraComment3
                ) {
                  return (
                    <Row key={key}>
                      <Row style={{ marginBottom: "30px" }}>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          {!editData?.isOpenCheckParentAsset ? (
                            editData?.extraComment3 ? (
                              <div>우연 인증팀 : {editData?.extraComment3}</div>
                            ) : null
                          ) : null}
                        </Col>
                      </Row>
                    </Row>
                  );
                }
              } else if (item.key === "checkParentAsset") {
                if (editData?.isOpenCheckParentAsset) {
                  return (
                    <div key={key}>
                      <Row key={key}>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          <div>
                            <label className="introlabelText">
                              {item.name}
                            </label>
                            <input
                              type="text"
                              className="js-input-mask introlabelInput"
                              readOnly
                              value={editData?.[item.key]}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          <div>{numberToKorean(editData?.[item.key])}</div>
                        </Col>
                      </Row> */}
                      <Row style={{ marginBottom: "30px" }}>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          {editData?.extraComment3 ? (
                            <div>우연 인증팀 : {editData?.extraComment3}</div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  );
                }
              } else if (item.key === "parentImmovableAmount") {
                if (editData?.isOpenParentImmovableAmount) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "checkParentImmovableAmount") {
                if (editData?.isOpenCheckParentImmovableAmount) {
                  return (
                    <Row key={key} style={{ marginBottom: "30px" }}>
                      <Col lg={12} style={{ padding: "0px 24px" }}>
                        <div>
                          <label className="introlabelText">{item.name}</label>
                          <input
                            type="text"
                            className="js-input-mask introlabelInput"
                            readOnly
                            value={editData?.[item.key]}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              } else if (item.key === "checkParentDebt") {
                if (editData?.isOpenCheckParentDebt) {
                  return (
                    <div key={key}>
                      <Row style={{ marginBottom: "30px" }}>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          <div>
                            <label className="introlabelText">
                              {item.name}
                            </label>
                            <input
                              type="text"
                              className="js-input-mask introlabelInput"
                              readOnly
                              value={editData?.[item.key]}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row style={{ marginBottom: "30px" }}>
                        <Col lg={12} style={{ padding: "0px 24px" }}>
                          <div>{numberToKorean(editData?.[item.key])}</div>
                        </Col>
                      </Row> */}
                    </div>
                  );
                }
              } else if (item.key === "parentIndep") {
                return (
                  <Row key={key} style={{ marginBottom: "30px" }}>
                    <Col lg={12} style={{ padding: "0px 24px" }}>
                      <div>
                        <label className="introlabelText">{item.name}</label>
                        <input
                          type="text"
                          className="js-input-mask introlabelInput"
                          readOnly
                          value={editData?.[item.key]}
                        />
                      </div>
                    </Col>
                    <Col lg={12} style={{ padding: "0px 24px" }}>
                      <div
                        style={{
                          wordBreak: "keep-all",
                        }}
                      >
                        기준 : 결혼 후 부모님의 생활비를 정기적으로 드려야
                        한다면 노후 준비 여부 '불가'로 판단
                      </div>
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row key={key} style={{ marginBottom: "30px" }}>
                    <Col lg={12} style={{ padding: "0px 24px" }}>
                      <div>
                        <label className="introlabelText">{item.name}</label>
                        <input
                          type="text"
                          className="js-input-mask introlabelInput"
                          readOnly
                          value={editData?.[item.key]}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              }
            })}
            {editData?.publicImages?.length > 0 ? (
              <div style={{ padding: "0px 38px" }}>
                <div style={{ position: "relative" }} ref={containerRef}>
                  {editData?.publicImages?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="sliderCustomRadius"
                        style={{ marginBottom: "20px" }}
                      >
                        <Link
                          to={`/image?src=${encodeURIComponent(item.url)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt={`Slide ${i + 1}`}
                            src={item.url}
                            style={{
                              maxWidth: "100%",
                              width: containerSize.width,
                              height: containerSize.height,
                              objectFit: "cover",
                            }}
                            onLoad={handleImageLoad}
                          />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            
            <Row style={{ marginTop: "19px" }}>
            { !useSubscribe ? (
              <Col style={{ padding: "0px 32px" }}>
                <div
                  className="productsInfoButtonWrap"
                  style={{ marginBottom: "18px", position: "relative" }}
                >
                  <div
                    className="productsInfoBtn"
                    onClick={(e) => onInfoModa(e)}
                  >
                    매칭권 안내
                    <Frame style={{ marginLeft: "4px" }} />
                  </div>
                </div>
              </Col>) : null}
            </Row>
            {likeStatus === "normal-like-pending" ? (
              <Row>
                <Col style={{ padding: "0px 20px" }}>
                  <div
                    className="introMainText"
                    style={{ margin: "0px 0px 40px 0px" }}
                  >
                    {/* {editData?.name}님에게 일반 호감을 보냈습니다. */}
                    {editData?.name}님에게 호감을 보냈습니다.
                    {likeMessage && likeMessage.length > 0 && (
                      <>
                        <br />
                        보낸 메세지는 다음과 같습니다.
                        <br />
                        {likeMessage}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col style={{ padding: "0px 20px" }}>
                {likeStatus === "premium-like-reject" ? (
                  <div className="introMainText" style={{ margin: "0px" }}>
                    {editData?.name}님께서 호감을 거절하셨습니다.
                    <br />
                    사유 : {likeAnswer}
                  </div>
                ) : likeStatus === "reject" ? (
                  <div className="introMainText" style={{ margin: "0px" }}>
                    회원님이 거절한 상대입니다.
                  </div>
                ) : likeStatus === "premium-like-pending" ? (
                  <div className="introMainText" style={{ margin: "0px" }}>
                    호감을 보낸 상대입니다. 보낸 메세지는 다음과
                    같습니다.
                    <br />
                    {likeMessage}
                  </div>
                ) : likeStatus === "accept" ? (
                  <div className="introMainText" style={{ margin: "0px" }}>
                    매칭 연결을 축하드립니다. 담당 직원이 만남 시간과 장소
                    선정을 위해 연락드릴 예정입니다.
                  </div>
                ) : (
                  null
                  // <div
                  //   className="introCustomButtonWrap"
                  //   style={{ marginBottom: "20px", position: "relative" }}
                  // >
                  //   <button
                  //     className="introCustomPrimiumButton"
                  //     onClick={() => premiumpostLike()}
                  //   >
                  //     프리미엄 호감 보내기
                  //   </button>
                  // </div>
                )}
              </Col>
            </Row>
            {likeStatus === "normal-like-reject" ? (
              <Row>
                <Col style={{ padding: "0px 20px" }}>
                  <div
                    className="introMainText"
                    style={{ marginBottom: "70px" }}
                  >
                    {editData?.name}님께서 호감을 거절 하셨습니다.
                    {/* 일반 호감을 거절 하셨습니다. 프리미엄 호감으로 재발송 가능합니다. */}
                    <br />
                    사유 : {likeAnswer}
                  </div>
                </Col>
              </Row>
            ) : likeStatus === "request" ? (
              <Row>
                <Col style={{ padding: "0px 20px" }}>
                  <div
                    className="introCustomButtonWrap"
                    style={{ marginBottom: "70px" }}
                  >
                    <button
                      className="introCustomPostButton"
                      onClick={() => nomalpostLike()}
                    >
                      호감 보내기
                    </button>
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>
          {callModal ? (
            <PremiumMadal
              setCallModal={setCallModal}
              postLike={postLike}
              onPremiumModal={onPremiumModal}
              onErrorModal={onErrorModal}
            />
          ) : null}
          {callPremiumModal ? (
            <PremiumPost
              setCallModal={setCallPremiumModal}
              postLike={postLike}
              message={message}
            />
          ) : null}
          {callNomalModal ? (
            <NomalPost setCallModal={setCallNomalModal} postLike={postLike} />
          ) : null}
          {callRejectModal ? (
            <RejectPost
              setCallModal={setCallRejectModal}
              postReject={postReject}
            />
          ) : null}
          {callRejectMessageModal ? (
            <RejectMassegeModal
              setModalOn={setCallRejectMessageModal}
              name={editData?.name}
              type={type}
              likeMessage={likeAnswer}
            />
          ) : null}
        </div>
      ) : isVisible ? (
        <WaringModal setWaring={setWaring} />
      ) : null}
      {callCompleteModal ? (
        <LikePostComplete
          setCallModal={setCallCompleteModal}
          message={completeMessage}
        />
      ) : null}
      {infoModal ? <InfoModal setModal={setInfoModal} /> : null}

      {callErrorModal ? (
        <ErrorModal
          message={errorMessage}
          setMessage={setErrorMessage}
          setCallModal={setCallErrorModal}
          error={true}
          setError={setError}
        />
      ) : null}
      {callErrorBackModal ? <ErrorModalBack message={errorMessage} /> : null}
    </React.Fragment>
  );
}

export default IntroDetail;
